<template>
  <div class="container">
    <div
      id="kt_subheader"
      class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <div class="text-lg-right"></div>
        </div>

        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            Admin Add Supplier / Brand
          </h5>
          <!--end::Page Title-->
          <!--begin::Action-->
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>

          <span class="text-muted font-weight-bold mr-4"> </span>
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>
          <!--end::Action-->
        </div>
        <!--end::Info-->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <v-card class="h-100" light>
          <v-card-title class="headline  lighten-3">
            Associate to a Company
          </v-card-title>
          <v-card-text> </v-card-text>

          <b-form class="form" @submit.stop.prevent="addUser">
            <v-card-text>
              <v-card-title class="headline  lighten-3">
                <strong> <span>New User Details </span></strong>
              </v-card-title>
              <v-divider></v-divider>
              <div class="form-group row">
                <label class="col-3 col-form-label text-dark">First Name</label>
                <div class="col-9">
                  <b-form-input
                    v-model="$v.firstname.$model"
                    class="form-control"
                    :state="validateState('firstname')"
                    aria-describedby="input-1-live-feedback"
                    placeholder=""
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    First name is required.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-3 col-form-label text-dark">Last Name</label>
                <div class="col-9">
                  <b-form-input
                    v-model="$v.lastname.$model"
                    class="form-control"
                    :state="validateState('lastname')"
                    aria-describedby="input-2-live-feedback"
                    placeholder=""
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-2-live-feedback">
                    Last name is required.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-3 col-form-label text-dark">Username</label>
                <div class="col-9">
                  <b-form-input
                    v-model="$v.username.$model"
                    class="form-control"
                    :state="validateState('username')"
                    aria-describedby="input-1-live-feedback"
                    placeholder=""
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Username is required.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-3 col-form-label text-dark">Email </label>
                <div class="col-9">
                  <b-form-input
                    v-model="$v.email.$model"
                    class="form-control"
                    :state="validateState('email')"
                    aria-describedby="input-3-live-feedback"
                    placeholder=""
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-3-live-feedback">
                    Email is required and a valid email.
                  </b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-3 col-form-label text-dark"
                  >Create Password
                </label>
                <div class="col-9">
                  <b-form-input
                    v-model="$v.password.$model"
                    class="form-control"
                    type="password"
                  />
                  <b-form-invalid-feedback id="input-3-live-feedback">
                    Password is required.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <!-- Send Email+

              <v-checkbox v-model="setEmail" label="Send Email"></v-checkbox> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-3" type="submit">
                  Create
                  <v-icon right>mdi-account-circle</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </b-form>
        </v-card>
      </div>

      <div class="col-lg-6"></div>
    </div>

    <div class="row">
      <div class="col-lg-6"></div>

      <div class="col-lg-6"></div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  data() {
    return {
      roleItems: [],
      company: null,
      search: '',
      companies: [],
      typeItems: ['seller', 'supplier'],
      type: 'seller',
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
      setEmail: false,
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    firstname: {
      required,
    },
    lastname: {
      required,
    },
    username: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions(['submitUser', 'sendEmail']),
    validateState(name) {
      const { $dirty, $error } = this.$v[name]
      return $dirty ? !$error : null
    },
    addUser(e) {
      e.preventDefault()
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      // if (this.company != null) {
      let setPassword = this.password
      const userRole = 4
      this.submitUser({
        FirstName: this.firstname,
        LastName: this.lastname,
        username: this.username,
        email: this.email,
        password: setPassword,
        company_relation: String(this.$store.state.auth.user.company_relation),
        role: userRole,
        approved: true,
        admin: false,
      })
        .then(() => {
          // if (this.setEmail) {
          //   let userCreatedHtml =
          //     '<h1>Your account is created in Goosery.<br/>Email: ' +
          //     this.email +
          //     '<br/>Username: ' +
          //     this.username +
          //     '<br/>Password: ' +
          //     this.password +
          //     '</h1>'
          //   this.sendEmail({
          //     to: this.email,
          //     subject: 'Your account is created in Goosery',
          //     text: 'Text',
          //     html: userCreatedHtml,
          //   })
          // }
          this.$router.push('/')
          Swal.fire({
            title: '',
            text: 'The user has been successfully created!',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })

          // location.reload();
        })
        .catch(() => {
          this.$bvToast.toast('An Error Occured!', {
            title: 'Error',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: true,
          })
        })
      // } else {
      //   this.$refs.autocomplete.focus()
      //   this.$bvToast.toast('Please select the company!', {
      //     title: 'An Error Occured!',
      //     variant: 'danger',
      //     toaster: 'b-toaster-bottom-right',
      //     solid: true,
      //     appendToast: true,
      //   })
      // }
    },
  },
}
</script>
